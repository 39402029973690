@use "../../../scss/theme/colors" as *;

.offer-row {
    position: relative;

    &::before {
        content: "";
        height: 24px;
        width: 1px;
        background-color: $gray-200;
        position: absolute;
        right: 44.5%;
        top: 50%;
        transform: translateY(-50%);
    }
}
