@use "../abstracts" as *;
@use "./spacing" as *;
@use "./links" as *;
@use "../theme/colors" as *;
@use "../theme/borders" as *;

$pad: rem(32);

// container
$min-width: 900px; // container has to be minimum of 900px (has to be greater than form wrapper (740))  - enables horizontal scrolling
$max-width: 1440px;

$navbar-height: 84px;
$navbar-min-width: 1216px; // cannot shrink below 1216px (has to be bigger than 900px (container min width))

$banner-height: 64px;

$banner-padding-y: rem(10);

// sidebar width on seat map
$sidebar-width-lg: 375px;
// dashboard, settings width sidebar
$sidebar-width: 314px;
// sidebar panel width on seat map
$sidebar-width-sm: 272px;
// dashboard, settings sidebar
$sidebar-padding: $text-spacer-sm $pad;
// sidebar on seat map
$sidebar-padding-sm: rem(24) rem(16) 0px $pad;
// sidebar panel on seat map
$sidebar-padding-x: rem(16);

body {
    overflow-x: auto; // enable horizontal scrolling
    overscroll-behavior-y: none; // removes overscrolling (rubberbanding effect) on Mac
}

// bootstrap adds padding to body on mobile when modals open
.modal-open {
    padding: 0px !important;
}

// my event pages
.spacer-md--with-banner {
    padding-top: $pad;
}

.container {
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;
    padding-right: $pad;
    padding-left: $pad;

    &--full-height {
        flex: 1;
    }
}

// on body and html
.full-height {
    height: 100%;
}

// on the root element
.full-height-flex {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.full-height-wrapper {
    @extend .full-height-flex !optional;
}

.backgroundWhite {
    background-color: #ffffff;
}

.sidebar-container {
    margin-left: $sidebar-width;
    max-width: 100%;
    min-width: $min-width;
    padding-right: $pad;
    padding-left: $pad;

    // seat map sidebar
    &-lg {
        margin-left: $sidebar-width-lg;
    }
}

.max-width-wrapper {
    max-width: 1130px;
    margin: auto;
}

// forms
.wrapper {
    max-width: 740px; // cannot grow beyond
    min-width: 740px; // cannot shrink below
    margin: auto;

    &-xs {
        max-width: 352px;
        margin: auto;
    }

    // package detail wrapper container => create package
    &-container--flex {
        margin: auto;
        max-width: 1000px;
        @extend .flex !optional;
        align-items: flex-start;
    }
}

// if no permission to page
.overlay {
    filter: blur(11px);
    pointer-events: none;
}

// package dashboard card
.h-auto {
    height: auto;
}

// create offer -> ticket limits input fields
.w-10 {
    width: 10%;
}

// create offer -> discount input field
.w-16 {
    width: 16%;
}

// create offer -> access code input group
.w-37 {
    width: 37%;
}

// create offer -> availability periods dropdown
.w-40 {
    width: 40%;
}

.w-max-content {
    width: max-content;
}

.btn-footer {
    position: fixed;
    left: $sidebar-width;
    right: 0;
    bottom: 0;
    padding: rem(12);
    background-color: $gray-300;
    border-top: border();
    transition: all 0.32s cubic-bezier(0.4, 0, 0.3, 1);

    &.without-sidebar {
        left: 0;
    }
    .wrapper,
    .max-width-wrapper {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@mixin remove-scrollbar() {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
}

// setting pages
.sidebar {
    width: $sidebar-width;
    background-color: $white;
    position: fixed;
    top: calc($navbar-height - 1px);
    left: 0;
    bottom: 0;
    z-index: 1;
    border-right: border();

    // dashboard pages
    &--with-banner {
        top: calc($navbar-height + $banner-height - 1px);
    }

    // seat map sidebar
    &-lg {
        width: $sidebar-width-lg;
        top: $banner-height;
    }

    // seat map sidebar panel
    &-sm {
        top: $banner-height;
        width: $sidebar-width-sm;
        left: $sidebar-width-lg;
    }

    // package seatmap
    &-reset {
        left: 0;
    }

    &-wrapper {
        height: 100%;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
        display: flex;
        flex-direction: column;
        padding: $sidebar-padding;
        @include remove-scrollbar();

        // seat map sidebar
        &-sm {
            padding: $sidebar-padding-sm;
            padding-bottom: 0px;
        }

        // seat map panel
        &-xs {
            padding: $sidebar-padding-sm;
            padding-left: $sidebar-padding-x;
            padding-right: $sidebar-padding-x;
            padding-bottom: 0px;
        }
    }
}

.navigation,
.banner {
    min-width: $navbar-min-width;
}

// seat map navigation and event banner
.banner {
    position: absolute; // entire page horizontal scrolling ignores position fixed elements
    top: $navbar-height;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: $white;
    border-bottom: border();
    display: flex;
    flex-direction: column;
    height: $banner-height;

    &-wrapper {
        display: flex;
        gap: $gap;
        justify-content: space-between;
        align-items: center;
        padding: $banner-padding-y $pad;
        height: 100%;

        .event-title {
            width: 57ch;
        }
    }

    &--sticky {
        position: sticky; // stays within flow of page
        margin-left: calc(
            -1 * ($sidebar-width + $pad)
        ); // move element back to left - more than sidebar width (314) + 32 - container padding (multiply by -1 to make result negative)
        width: 100vw; // will only span width of parent container, this ensure it spans whole viewport
    }
    &--seatmap {
        top: 0;
        min-width: calc($navbar-min-width + 57px); // banner needs more min width on smaller viewports
        .banner-wrapper {
            padding-left: rem(24);
        }
    }
}
.d-flex-column {
    display: flex;
    flex-direction: column;
}
%icon-text-box {
    display: flex;
    gap: $gap-md;
    align-items: center;
    &::before,
    &::after {
        line-height: 0;
    }
}
// price modal -> seatmap -> create event flow
.icon-text-box {
    @extend %icon-text-box !optional;
    gap: $gap-xs;
}
.block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 145px;
    border-radius: $border-radius-sm;
    @include breakpoint-min(md) {
        flex-basis: 145px;
    }
}
// seat map unassigned seats, pricing levels, holds, kills, availability periods -> create offer
.list-item--hover {
    .btn-actions {
        display: none;
    }
    &:hover,
    &:focus,
    &:active,
    &.selected {
        @include hover-effect($br-xs);
    }
    &:hover,
    &:focus {
        .btn-actions {
            display: flex;
        }
    }
    // availability periods -> create offer
    &-lg {
        &:hover,
        &:focus,
        &:active,
        &.selected {
            @include hover-effect($border-radius-sm);
        }
    }
    // seat map - select map modal items
    &-primary {
        &:hover,
        &:focus,
        &:active,
        &.selected {
            @include hover-effect($border-radius-sm, rgba($primary, 0.1), border($border-width: 2px, $color: $primary));
        }
    }
}
.event-image {
    aspect-ratio: 1/1;
}
// browsers that do not support aspect-ratio like Explorer and Safari
@supports not (aspect-ratio: 1/1) {
    /* defined that the height of the depends on its width */
    .image-wrapper {
        position: relative;
        padding-top: 100%;
        // aspect-ratio 1 / 1
        /* image is absolutely positioned and it has the full width and height of its parent, with object-fit: cover for cases of uploading a different-sized image. */
        .event-image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
// seat map and create offer pricing levels, holds/kills, on sale icon
$dot-width: 20px;
$dot-width-sm: 10px;
%dot {
    border-radius: 50%;
    height: $dot-width;
    width: $dot-width;
    &-sm {
        width: $dot-width-sm;
        height: $dot-width-sm;
    }
}
// item name - price level, holds, kills
.dot {
    @extend %dot !optional;
    &-sm {
        @extend %dot-sm !optional;
    }
}
// availabity status icons -> edit offer
$square-width: 28px;
%square {
    border-radius: $border-radius-sm;
    width: $square-width;
    height: $square-width;
}
// global icon sizes
%icon-size {
    &-sm {
        width: rem(16);
    }
    &-md {
        width: rem(24);
    }
    &-lg {
        width: rem(32);
    }
}
// availabity status icons - info text -> edit offer
%badge-icons {
    &.info-text {
        &::before {
            content: "";
            @extend %square !optional;
            align-self: flex-start;
        }
        &::after {
            position: absolute;
            top: calc($square-width / 2);
            left: calc($square-width / 2);
            right: calc($square-width / 2);
            transform: translate(-50%, -50%);
        }
    }
}
// tracking links, contact attendees, offers, guest list, events list, daily ticket counts, assign packaging -> season packaging
.list-table {
    // narrowest columns - tracking links
    &.eight-col {
        .list-table-col:not(.btn-more-col) {
            width: calc(100% / 8);
        }
    }
    // narrower columns - offers page - pricing and fees
    &.seven-col {
        .list-table-col:not(.btn-more-col) {
            width: calc(100% / 6.6);
        }
    }
    // wider columns - guest list
    // pricing level descriptin -> create offer
    &.five-col {
        .list-table-col:not(.btn-more-col) {
            width: calc(100% / 5.5);
        }
    }
    // wider columns - events list - home page
    // assigning packages, fan history -> season packaging
    &.four-col {
        .list-table-col:not(.btn-more-col) {
            width: calc(100% / 4);
        }
    }
    // wider columns - contact attendees
    &.three-col {
        .list-table-col:not(.btn-more-col) {
            width: calc(100% / 3.6);
        }
    }
    // widest columns - contact attendees
    // package holders -> season packaging
    &.two-col {
        .list-table-col:not(.btn-more-col) {
            width: calc(100% / 3);
        }
    }
    .list-table-col {
        // pricing and fees -> offers
        // assign packaging, fan history -> season packaging
        &.sm {
            width: 11% !important;
        }
        // package holders -> season packaging
        &.md {
            width: 13% !important;
        }
        // pricing and fees -> offers
        // assign packaging, package holders, fan history -> season packaging
        &.lg {
            width: 18% !important;
        }
        // tracking links - link
        &.xl {
            width: 44% !important;
        }
        // pricing description -> offers
        &.xxl {
            width: 66% !important;
        }
    }
}
